import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "/src/components/layout"
import { StaticImage } from "gatsby-plugin-image"
import CalendarIcon from "/src/images/calendar.svg"
import moment from 'moment'

const BlogCategory = ({ data }) => {
  const category = data.strapiCategory
  return (
    <Layout>
      <div className="container px-4 py-5" id="custom-cards">
        <h1 className="pb-2 border-bottom">{category.Name}</h1>
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          {data.featured.edges.map(article => (
            <div className="col" key={article.node.id}>
              <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: `url(${article.node.Cover.formats.medium.url})` }}>
                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h2 className="pt-5 mt-5 mb-4 lh-1 fw-bold">
                    <Link to={`/article/${article.node.Slug}/`} className="anchor-full">
                      {article.node.Title}
                    </Link>
                  </h2>
                  <p className="text-white text-shadow-1">{article.node.Preview}</p>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <StaticImage src="../images/codingsamples-dark.png" alt="Bootstrap" width={32} className="rounded-circle border border-white" />
                    </li>
                    <li className="d-flex align-items-center">
                      <CalendarIcon className="me-2" />
                      <small>{moment(article.node.Creation).format('LL')}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
    </Layout >
  )
}
export default BlogCategory;

export const query = graphql`
  query($Slug: String!) {
    strapiCategory(Slug: { eq: $Slug }) {
      Description
      Name
    }
    featured: allStrapiArticle(filter: {category: {Slug: {eq: $Slug}}}) {
      edges {
        node {
          id
          Title
          Preview
          Creation
          Slug
          Cover {
            formats {
              small {
                url
              }
              medium {
                url
              }
            }
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }    
    }
  }
`